<template>
  <div>
    <v-container
        v-if="pending_payments"
    >
      <v-row
          justify="center"
      >
        <v-col
            cols="12"
            md="12"
        >
          <v-row>
            <v-col
                cols="12"
                md="12"
            >
              <v-card-title>
                <div>
                  <v-breadcrumbs :items="links" />
                </div>
                <v-spacer />
                <div
                    class="text-h3 text-center font-weight-medium"
                >
                  Upload a new bookings
                </div>
                <v-spacer />
                <v-spacer />
              </v-card-title>
            </v-col>
          </v-row>
          <v-container
              class="rounded elevation-0 pt-16 pb-16"
          >
            <v-alert
                :value="alert"
                type="danger"
                border="left"
                elevation="2"
                colored-border
                icon="mdi-check-circle-outline">
              Kindly settle your account payments to continue using this service, OR contact your account manager.
            </v-alert>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-container
        v-else-if="!isMakerChecker"
        id="user-profile"
        fluid
        tag="section"
    >
      <v-row
          justify="center"
      >
        <v-col
            cols="12"
            md="12"
        >
          <v-row>
            <v-col
                cols="12"
                md="12"
            >
              <v-card-title>
                <div>
                  <v-breadcrumbs :items="links" />
                </div>
                <v-spacer />
                <div
                    class="text-h3 text-center font-weight-medium"
                >
                  Upload a new bookings
                </div>
                <v-spacer />
                <v-spacer />
              </v-card-title>
            </v-col>
          </v-row>
          <v-container
              class="rounded elevation-0"
          >
            <v-form
                v-model="valid"
                class="rounded elevation-2 pa-3"
                @submit.prevent="UploadFile"
            >
              <v-row class="mt-2">
                <v-col
                    cols="4"
                    md="3"
                >
                  <v-select
                      v-model="deductionCode"
                      :items="dasCodes"
                      item-text="deductionCode"
                      item-value="deductionCode"
                      label="Select Deduction Code"
                      outlined
                      :rules="[rules.required]"
                      style="text-transform: uppercase"
                      @change="getPayeeCodes()"
                  />
                </v-col>
                <v-col
                    cols="4"
                    md="3"
                >
                  <v-text-field
                      v-model="dasCurrency"
                      outlined
                      label="Currency"
                      class="purple-input my-input"
                      autocomplete="off"
                      disabled="true"
                      :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                    cols="4"
                    md="3"
                >
                  <v-select
                      v-model="payeeCode"
                      :items="payeeCodes"
                      item-text="payeeCode"
                      item-value="payeeCode"
                      label="Select Payee Code"
                      outlined
                      :rules="[rules.required]"
                      style="text-transform: uppercase"
                  />
                  <v-text-field
                      v-if="false"
                      v-model="payeeCode"
                      outlined
                      label="Payee Code"
                      class="purple-input my-input"
                      autocomplete="off"
                      disabled="true"
                      :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                    cols="4"
                    md="3"
                >
                  <input class="mt-3" type="file" ref="myFile" @change="PreviewFile" hidden>
                  <v-file-input
                      @change="PreviewFile"
                      v-model="files"
                      accept=".xls , .ins , .bak , .xlsx"
                      placeholder="Upload your document"
                      label="File input"
                      multiple
                      outlined
                      prepend-icon="mdi-paperclip"
                      :rules="[rules.required]"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip
                          small
                          label
                          color="primary"
                      >
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col
                    cols="12"
                    class="text-center mb-1"
                >
                  <v-btn
                      color="primary"
                      :disabled="!valid"
                      @click="UploadFile"
                  >
                    Upload
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-row class="mt-2">
            <v-col cols="1" md="1" style="justify-content: center">

            </v-col>
            <v-col cols="10" md="10" style="background-color: whitesmoke; justify-content: center">
              <v-card-title>
                Preview
                <v-spacer></v-spacer>
                <br>
              </v-card-title>
              <v-data-table
                  :headers="headers"
                  :items="uploadData"
              >
                <template v-slot:item.startDate="{ item }">
                  <span v-if="item.startDate === 'Invalid date'" style="color: #e91e63">Invalid Date</span>
                  <span v-else >{{ item.startDate }}</span>
                </template>
                <template v-slot:item.endDate="{ item }">
                  <span v-if="item.endDate === 'Invalid date'" style="color: #e91e63">Invalid Date</span>
                  <span v-else >{{ item.endDate }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog
          v-model="successUpload"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Upload Status
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #4caf50"
            >
              Upload Successful
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/success.svg"
                        width="8em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="goTo()"
                  >
                    Done
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="errorUpload"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Upload Status
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #800808"
            >
              Records already exist
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/error.svg"
                        width="8em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="goTo()"
                  >
                    Resubmit
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="errorUpload2"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Upload Status
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #800808"
            >
              Invalid File Type
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/error.svg"
                        width="8em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="clear()"
                  >
                    Resubmit
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="errorUpload3"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title  v-if="true">
              <h3 class="text-center justify-center">Data Error</h3>

            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                class="pt-6"
                style="color: #800808;"
            >
              {{ ECRefError }}
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="clear()"
                  >
                    Resubmit
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>

    </v-container>
    <v-container
        v-else
    >
      <v-row
          justify="center"
      >
        <v-col
            cols="12"
            md="12"
        >
          <v-row>
            <v-col
                cols="12"
                md="12"
            >
              <v-card-title>
                <div>
                  <v-breadcrumbs :items="links" />
                </div>
                <v-spacer />
                <div
                    class="text-h3 text-center font-weight-medium"
                >
                  Upload a new bookings
                </div>
                <v-spacer />
                <v-spacer />
              </v-card-title>
            </v-col>
          </v-row>
      <v-container
          class="rounded elevation-0 pt-16 pb-16"
      >
        <v-alert
            :value="alert"
            type="warning"
            border="left"
            elevation="2"
            colored-border
            icon="mdi-check-circle-outline">
          You have no permission to perform this action
        </v-alert>
      </v-container>
        </v-col>
      </v-row>
    </v-container>
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular
            indeterminate
            size="64"
        />
      </v-overlay>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
import { API } from '../../../api'
import moment from 'moment'
import dataToJson from "data-to-json";
import * as XLSX from "xlsx";
import {getUserIP} from "@/services/userIp";
export default {
  name: 'Booking',
  data () {
    return {
      dasCodes: [],
      pending_payments:false,
      isMakerChecker: false,
      payeeCodes:[],
      overlay: false,
      files: [],
      valid:false,
      invalidity: false,
      successUpload:false,
      errorUpload: false,
      errorUpload2: false,
      errorUpload3: false,
      dasCurrency: '',
      ECRefError: '',
      deductionCode: '',
      payeeCode: '',
      uploadData:[],
      headers: [
          { text: 'Reference', value: 'reference' },
          { text: 'IdNumber', value: 'idNumber' },
          { text: 'EcNumber', value: 'ecNumber' },
          { text: 'Type', value: 'type' },
          { text: 'StartDate', value: 'startDate' },
          { text: 'EndDate', value: 'endDate' },
          { text: 'Amount(cents)', value: 'amount' },
        ],
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length >= 10 || 'Max 10 characters',
        counter_id: value => value.length >= 11 || 'Max 11 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      links: [
        {
          text: 'SSB',
          disabled: false,
          href: 'generate',
        },
        {
          text: 'Bulk booking',
          disabled: true,
          href: '',
        },
      ],
    }
  },
  computed: {
    ...mapState(['maker_checker','invoice_paid']),
  },
  created () {
    this.getDasCodes()
    if(!this.$store.getters.invoice_paid && !this.$store.getters.unpaidInvoiceAccess){
      this.pending_payments = true
    }
    if (this.$store.getters.maker_checker && this.$store.getters.user_type === 'APPROVER') {
      this.isMakerChecker = true
    }
    else{
      this.isMakerChecker = false
      this.getBookings()
      this.getAllMyBookings()
    }
  },
  methods: {
    async getDasCodes() {
      const JWTToken = this.$store.getters.accessToken
      axios.get('/das/payeeCode_and_currency/?organization_id=' + this.$store.getters.OrgId)
          .then(res => {
            if (res.status === 200) {
              this.dasCodes = res.data
              console.log(this.dasCodes)
            } else if (res.status === 202) {
              console.log('202')
            } else {
              console.log('error')
            }
          })
    },
    async getPayeeCodes() {
      const dascode = this.dasCodes.find(item => item.deductionCode === this.deductionCode)
      this.dasCurrency = dascode.currency
      this.payeeCodes = this.dasCodes.filter(item => item.deductionCode === this.deductionCode)
      console.log(this.payeeCodes)
    },
    async populateDasCodes() {
      const dascode = this.dasCodes.find(item => item.deductionCode === this.deductionCode)
      this.payeeCode = dascode.payeeCode
      this.dasCurrency = dascode.currency
    },
    async PreviewFile(){
      this.invalidity = false
      this.overlay = true
      let file = this.files[0]
      if(file == undefined){
        this.overlay = false
        this.uploadData = []
      }
      else{
        let FileExt = file.name.split('.')[1]
        if(FileExt =='xlsx') {
          const reader = new FileReader();
          const that = this
          reader.onload = function (e) {
            const data = e.target.result;
            const workbook = XLSX.read(data, {
              type: 'binary'
            });
            workbook.SheetNames.forEach(function (sheetName) {
              // Here is your object
              const XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              const json_object = JSON.stringify(XL_row_object);
              console.log(json_object);
              XL_row_object.forEach((node) => node.startDate = that.sanitizeXslSDate(node.startDate));
              XL_row_object.forEach((node) => node.endDate = that.sanitizeXslEDate(node.endDate));
              XL_row_object.forEach((node) => that.ValidateECRef(node.ecNumber, node.reference, node.idNumber));
              //
              let newData = that.RemoveDupicate(XL_row_object,'idNumber')
              // that.uploadData = XL_row_object;
              that.uploadData = newData;

              return json_object;
            })

          };
          reader.onerror = function (ex) {
            console.log(ex);
          };
          reader.readAsBinaryString(file);
        }
        else if (FileExt =='ins' || FileExt =='bak'){
          let reader = new FileReader();
          const dataInJSON = [];
          const that = this
          reader.readAsText(file, "UTF-8");
          reader.onload = evt => {
            this.text = evt.target.result;
            this.text = this.text.substring(this.text.indexOf("DED") + 0);
            const lines = this.text.split('\n');
            for (let line = 0; line < lines.length; line++) {
              let ecNumber = lines[line].substring(3,11).trim();
              let type = lines[line].substring(11,12).trim();
              let deductionCode = lines[line].substring(12,16).trim();
              let payeeCode = lines[line].substring(16,21).trim();
              let reference = lines[line].substring(21,33).trim();
              let startDate = lines[line].substring(33,41).trim();
              let endDate = lines[line].substring(41,49).trim();
              let idNumber = lines[line].substring(49,64).trim();
              let amount = lines[line].substring(64,80).trim();
              startDate = this.sanitizeInsSDate(startDate)
              endDate = this.sanitizeInsEDate(endDate)
              amount = this.sanitizeInsAmount(amount)
              const myObj = {
                "ecNumber": ecNumber,
                "type": type,
                "deductionCode": deductionCode,
                "payeeCode": payeeCode,
                "reference": reference,
                "startDate": startDate,
                "endDate": endDate,
                "idNumber": idNumber,
                "amount": amount
              };
              if(startDate != 'Invalid date'){
                dataInJSON.push(myObj);
              }
              dataInJSON.forEach((node) => this.ValidateECRef(node.ecNumber, node.reference, node.idNumber));
            }
            // const firstLine = this.text.substring(0, this.text.indexOf("DED"));
            // this.text = this.text.substring(this.text.indexOf("DED") + 0);
            // this.text = this.text.split('TRL')[0];
            // this.text = "DED000000FBC000000000FBC         FBC0002023FBC000002000000FBC   FBC0000000000FBC  \n" + this.text;
            // const dataInJSON = dataToJson.txt({data: this.text}).toJson();
            // dataInJSON.forEach((node) => node.ecNumber = this.sanitizeInsEc(node.DED000000FBC000000000FBC));
            // dataInJSON.forEach((node) => node.type = this.sanitizeInsType(node.DED000000FBC000000000FBC));
            // dataInJSON.forEach((node) => node.deductionCode = this.sanitizeInsDed(node.DED000000FBC000000000FBC));
            // dataInJSON.forEach((node) => node.payeeCode = this.sanitizeInsPayee(node.DED000000FBC000000000FBC));
            // dataInJSON.forEach((node) => node.reference = this.sanitizeInsReference(node.DED000000FBC000000000FBC));
            // dataInJSON.forEach((node) => delete node.DED000000FBC000000000FBC);
            // dataInJSON.forEach((node) => node.startDate = this.sanitizeInsSDate(node.FBC0002023FBC000002000000FBC));
            // dataInJSON.forEach((node) => node.endDate = this.sanitizeInsEDate(node.FBC0002023FBC000002000000FBC));
            // dataInJSON.forEach((node) => node.idNumber = this.sanitizeInsId(node.FBC0002023FBC000002000000FBC));
            // dataInJSON.forEach((node) => delete node.FBC0002023FBC000002000000FBC);
            // dataInJSON.forEach((node) => node.amount = this.sanitizeInsAmount(node.FBC0000000000FBC));
            // dataInJSON.forEach((node) => delete node.FBC0000000000FBC);
            console.log(dataInJSON)
            let newData = that.RemoveDupicate(dataInJSON,'idNumber')
            that.uploadData = newData;
            // that.uploadedData = this.uploadedData.concat(dataInJSON)
          }
          reader.onerror = evt => {
            this.overlay = false
            console.error(evt);
          }
          this.overlay = false
        }
        else{
          this.overlay = false;
          this.valid = false;
          this.errorUpload2 = true;
        }
      }
      this.overlay = false
  },
    RemoveDupicate(array, key){
      // const check = new Set();
      // return array.filter(obj => !check.has(obj[key]) && check.add(obj[key]));
      return array.filter((obj, index, self) =>
          index === self.findIndex((t) => (
              t.reference === obj.reference && t.idNumber === obj.idNumber
          )));
    },

    ValidateECRef(ecNumber, reference, idNumber){
      let regex = /[0-9]+[A-Za-z]/i;
      let idregex = /^[0-9]+[A-Za-z]\d\d$/i;
      let EcValidity = regex.test(ecNumber);
      let idValidity = idregex.test(idNumber);
      if (!EcValidity || ecNumber.length != 8){
        console.log("Invalid EC Number  - " + ecNumber);
        this.ECRefError = "Invalid EC Number  - " + ecNumber,
        this.errorUpload3 = true;
      }
      if (reference == "" || reference == null){
        console.log("Reference is empty - " + reference);
      }
      else if (reference.length > 12 && this.$store.getters.institution!=='MICROPLAN')
      {
        this.ECRefError = "Reference is greater than 12 characters - " + reference,
        this.errorUpload3 = true;
      }
      if (!idValidity || idNumber.length < 11 || idNumber.length > 12){
        this.ECRefError = "Invalid ID Number - " + idNumber,
            this.errorUpload3 = true;
      }

    },

    async UploadFile(){
      this.overlay = true
      console.log(this.$store.getters.OrgId)
      if (this.$store.getters.OrgId == "1")
      {
        this.uploadData.forEach((node) => node.reference = this.GenerateRef(node.reference));
      }
      this.uploadData.forEach((node) => node.currency = this.dasCurrency);
      // this.uploadData.forEach((node) => node.amount = this.SanitizeAmount(node.amount));
      // this.uploadData.forEach((node) => node.batchNumber = this.GenerateBatchID(this.uploadData));
      // this.uploadData = this.RemoveDupicate(this.uploadData,'idNumber')
      if(this.$store.getters.user_type === 'INITIATOR' && this.$store.getters.maker_checker === true){
        this.uploadData.forEach((node) => node.status = 'PendingApproval');
      }
      else{
        this.uploadData.forEach((node) => node.status = 'Pending');
      }
      this.uploadData.userIP = getUserIP()
      console.log(this.uploadData)
      await new Promise((resolve) => setTimeout(resolve, 3000));
      const JWTToken = this.$store.getters.accessToken
      await axios
          .post("/das/post_bookings/?payeeCode=" + this.payeeCode + "&deductionCode=" + this.deductionCode + "&organization=" + this.$store.getters.institution, this.uploadData
              ,{ headers: { Authorization: `Bearer ${JWTToken}` } })
          .then((response) => {
            console.log(response)
            this.overlay = true;
            if (response.status === 201) {
              console.log(response.data);
              this.overlay = false;
              this.successUpload = true;
              // this.$router.push("/user/dashboard").catch(() => {})
            } else if (response.status === 404){
              this.overlay = false;
              console.log('gjkbsadkbasd')
            }
            else {
              this.overlay = false;
              this.errorUpload = true;
              console.log(response)
            }
          })
          .catch((error) => {
            if (error.response.status === 400) {
              this.overlay = false;
              this.errorUpload = true;
            } else if (error.response.status === 404) {
              this.overlay = false;
              this.errorUpload = true;
            } else if (error.response.status === 500) {
              this.overlay = false;
              this.errorUpload = true;
            }else{
              this.overlay = false;
              this.errorUpload = true;
            }
          });

    },
    GenerateRef(val){
      val = val.substring(1);
      val = val.slice(0, -2);
      return val;
    },
    GenerateRefID(val){
      // const Org = this.$store.getters.institution.substring(0, 1).toUpperCase();
      // let randomNum = Math.floor((Math.random() * 800) + 111)
      // const current =  moment().format("YYMMDDmmS");
      val = val.slice(0, 7);
      const RefID =  val + this.payeeCode;
      return RefID;
    },
    SanitizeAmount(val){
      const SanitisedAmount = val.toFixed(0);
      return SanitisedAmount;
    },
    GenerateBatchID(val){
      const Org = this.$store.getters.institution.substring(0, 3).toUpperCase();
      const TodayDate = moment().format("YYMMDDms");
      const BatchID = Org + TodayDate + val.length
      return BatchID;
    },
    sanitizeInsEc(val){
      val = val.substring(3,11);
      return val;
    },
    sanitizeInsType(val){
      val = val.substring(11,12);
      return val;
    },
    sanitizeInsDed(val){
      val = val.substring(12,16);
      return val;
    },
    sanitizeInsPayee(val){
      val = val.substring(16,21);
      return val;
    },
    sanitizeInsReference(val){
      val = val.substring(22,34);
      console.log(val)
      return val;
    },
    sanitizeInsSDate(val){
      // val = val.substring(0,8);
      // let year = val.substring(4,8);
      // let month = val.substring(2,4)
      // let day = val.substring(0,2)
      // const d = new Date('2019/06/01');
      val = moment(val).format('DD/MMM/YYYY');
      if(val == 'Invalid date'){
        this.invalidity = true
      }
      if(val == '01/Jan/!970'){
        this.invalidity = true
      }
      return val;
    },
    sanitizeInsEDate(val){
      // val = val.substring(8,16);
      if(val == '        '){
        val = '        '
      }else if(val == '')
      {
        val = '        '
      }
      else{
        val = moment(val).format('DD/MMM/YYYY');
        if(val == 'Invalid date'){
          this.invalidity = true
        }
      }
      return val;
    },
    sanitizeXslSDate(val){
      // let year = val.substring(4,8);
      // let month = val.substring(2,4)
      // let day = val.substring(0,2)
      // const d = new Date('2019/06/01');
      val = moment(val).format('DD/MMM/YYYY');
      if(val == 'Invalid date'){
        this.invalidity = true
      }
      if(val == '01/Jan/1970'){
        this.invalidity = true
      }
      console.log(this.invalidity)
      return val;
    },
    sanitizeXslEDate(val){
      console.log('before format', val)
      if(val == undefined){
        val = '        '
      }
      else{
        val = moment(val).format('DD/MMM/YYYY');
        if(val == 'Invalid date'){
          this.invalidity = true
        }
      }
      return val;
    },
    sanitizeInsId(val){
      val = val.substring(16);
      return val;
    },
    sanitizeInsAmount(val){
      let amount = parseInt(val)
      return amount;
    },
    sanitizeRef(val){
      val = val.substring(8);
      return val;
    },
    sanitizeEC(val){

      val = val.substring(0,8);
      return val;
    },
    sanitizeName(val1,val2){
      let fullname = val1 + "" + val2;
      return fullname;
    },
    goTo(){
      this.$router.push('/user/batch')
    },
    clear () {

      this.overlay = false,
      this.valid = false,
          this.ECRefError = '',
      this.invalidity = false,
      this.successUpload = false,
      this.errorUpload = false,
      this.errorUpload2 = false,
          this.errorUpload3 = false,
      this.dasCurrency = '',
      this.deductionCode = '',
      this.payeeCode = '',
          this.files = [],
      this.uploadData = []
    },
  },
}
</script>
<style>
.my-input input{
  text-transform: uppercase
}
</style>
