<template>
  <div class="pt-8">
    <div
        id="login-page"
    >
      <br><br>
      <h1  style="color: #00587A; font-weight: bold">SSB - Deduct At Source</h1>
      <br>
      <div
          id="container"
          :class="(SignUpState)?'container1 right-panel-active':'container1'"
      >
        <div>
          <div class="form-container sign-up-container">
            <form action="#">
              <h1>Set New Password</h1>
              <div class="social-container">
                <a
                    href="#"
                    class="social"
                />
                <a
                    href="#"
                    class="social"
                />
                <a
                    href="#"
                    class="social"
                />
              </div>
              <span>enter your new password below</span><br>
              <input
                  v-model="password_1"
                  class="input_class"
                  type="password"
                  placeholder="Enter new password"
                  :rules="[rules.required, rules.password]"
              >
              <input
                  v-model="password_2"
                  class="input_class"
                  type="password"
                  placeholder="Confirm password"
                  @keyup="checkPasswords()"
                  :rules="[rules.required,rules.password]"

              >
              <br>
              <v-card-text
                  v-if="password_error"
                  style="color: #800808"
              >
                Passwords do not match
              </v-card-text>
              <button
                  class="button_class"
                  @click="changePassword()"
              >
                Reset Password
              </button>
            </form>
          </div>
          <div class="form-container sign-in-container">
            <form action="#">
              <h1>Sign in</h1>
              <div class="social-container">
                <a
                    href="#"
                    class="social"
                />
                <a
                    href="#"
                    class="social"
                />
                <a
                    href="#"
                    class="social"
                />
              </div>
              <span>enter your username & password</span>
              <br>
              <input
                  v-model="id_number"
                  class="input_class"
                  type="text"
                  placeholder="Username"
                  autocomplete="off"
                  required
              >
              <input
                  v-model="password"
                  class="input_class"
                  type="password"
                  placeholder="Password"
                  autocomplete="off"
                  required
              >
              <button
                  class="button_class"
                  @click="login"
              >
                Sign In
              </button>
            </form>
          </div>
        </div>
        <div class="overlay-container">
          <div class="overlay">
            <div>
              <div class="overlay-panel overlay-left">
                <h1>Set your new password</h1>
                <p>Enter your preferred password and keep it safe as your new login password</p>
                <button
                    v-if="false"
                    class="button_class ghost"
                    @click="SignUpState = false"
                >
                  Back to login
                </button>
              </div>
              <div class="overlay-panel overlay-right">
                <h1>{{ welcome_message }}!</h1>
                <p>Please sign-in with your username and password</p>
                <button
                    v-if="false"
                    class="button_class ghost"
                    @click="SignUpState = true"
                >
                  Reset password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br><br><br><br>
      <img
          v-if="true"
          src="./../assets/xarani-nobg.png"
          class="pt-3 mt-4"
          style="width: 10%; text-align: center;"
          alt=""
      >
    </div>
    <div data-app>
      <v-dialog
          v-model="error_message"
          width="300"
      >
        <v-card>
          <v-toolbar
              style="background-color: #00587A"
          >
            <v-btn
                icon
                dark
                @click="error_message = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title style="color: aliceblue">Information</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-spacer />
          <v-card-text style="color: black; text-align: center; font-weight: bold ">
            {{ info_desc }}
          </v-card-text>
          <v-card-text
              class="pb-6 pt-12"
              style="text-align: center"
          >
            <v-btn
                outlined
                style="color: aliceblue; background-color: #00587A"
                @click="clear()"
            >
              Close
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular
            indeterminate
            size="64"
        />
      </v-overlay>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
import {getUserIP} from "@/services/userIp";
export default {
  name: 'Login',
  computed: {
    ...mapState(['base_url']),
  },
  data () {
    return {
      payload: {},
      password_2: null,
      password_1: null,
      overlay: false,
      password_error: false,
      reset_true: false,
      SignUpState: false,
      welcome_message: 'hie',
      id_number: null,
      password: null,
      error_message: false,
      info_type: 'info',
      info_desc: '',
      email: null,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        password: value => {
          const pattern = /"^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },
  created() {
    console.log(getUserIP())
  },
  beforeMount() {
    const d = new Date()
    const hour = d.getHours()
    // if else check
    if (hour < 11) {
      this.welcome_message = 'Good Morning'
    } else if (hour > 11 && hour <= 15) {
      this.welcome_message = 'Good Afternoon'
    } else if (hour > 15 && hour <= 20) {
      this.welcome_message = 'Good Day'
    } else {
      this.welcome_message = 'Good Day'
    }
  },
  methods: {
    async login (e) {
      e.preventDefault()
      const payload = {
        "userIP": await getUserIP(),
        "username": this.id_number,
        "id_number": this.id_number,
        "test": "test",
        "password": this.password
      }
      console.log(payload)
      this.overlay = true
      await new Promise((resolve) => setTimeout(resolve, 3000));
      await axios
          .post("/das/auth/login/", payload)
          .then((response) => {
            console.log(response)
            this.overlay = false;
            if (response.status === 200) {
              console.log(response.data);
              this.overlay = false;
              if(response.data.password_reset){
                this.SignUpState = true
              }
              else{
                this.$store.commit("setRefreshToken", response.data.tokens.refresh);
                this.$store.commit("setAccessToken", response.data.tokens.access);
                this.$store.commit("setLoggedInUser", response.data.id_number);
                this.$store.commit("setIsSSB", response.data.is_ssb);
                this.$store.commit("setIsBss", response.data.is_bss);
                this.$store.commit("setIsAccounts", response.data.is_accounts);
                this.$store.commit("setIsFBCManager", response.data.is_fbc);
                this.$store.commit("setColor", response.data.color);
                this.$store.commit("setUserType", response.data.user_type);
                this.$store.commit("setMakerChecker", response.data.organisation_makerChecker);
                this.$store.commit(
                    "setLogo",
                    this.$base_url + "/media/" + response.data.logo
                );
                this.$store.commit("setAuthenticated", true);
                this.$store.commit("setUnpaidInvoiceAccess", response.data.unpaidInvoiceAccess);
                this.$store.commit("setinstitution", response.data.organisation_name);
                this.$store.commit("setOrgId", response.data.organisation_id);
                if(response.data.is_bss){
                  this.$router.push("/user/dashboard-bss").catch(() => {})
                }
                if(response.data.is_accounts){
                  this.$router.push("/user/bss-invoices").catch(() => {})
                }
                else if (response.data.is_fbc){
                  this.$router.push("/user/dashboard").catch(() => {})
                }
                else{
                  this.$router.push("/user/dashboard").catch(() => {})
                }
              }
            } else {
              this.overlay = false;
              this.info_type = 'error'
              this.info_desc = 'Invalid Credentials'
              this.error_message = true
              console.log(response)
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.overlay = false;
              this.badcredentials = true;
              this.info_type = 'info'
              this.info_desc = 'Invalid Credentials'
              this.error_message = true
            } else if (error.response.status === 400) {
              this.overlay = false;
              this.badcredentials = true;
              this.info_type = 'info'
              this.info_desc = 'Invalid Credentials'
              this.error_message = true
            } else if (error.response.status === 500) {
              this.overlay = false;
              this.badcredentials = true;
              this.info_type = 'info'
              this.info_desc = 'Invalid Credentials'
              this.error_message = true
              console.log('Hit 500')
              // location.replace("/login");
            }else{
              this.overlay = false;
              this.badcredentials = true;
              this.info_type = 'info'
              this.info_desc = 'Invalid Credentials'
              this.error_message = true
              // alert("System Error. Please login again.");
              // location.replace("/login");
            }
          });
    },
    // Check if passwords are the same
    checkPasswords () {
      if (this.password_1 !== this.password_2) {
        console.log("Password 1", this.password_1)
        console.log("Password 2", this.password_2)
        this.password_error = true
      }
      else {
        this.password_error = false
      }
      return this.password_error
    },
    async changePassword () {
      this.overlay = true
      if (!this.password_error){
        const payload = {
          userIp: getUserIP(),
          id_number: this.id_number,
          access_key: this.password_2
        }
        console.log(payload)
        const JWTToken = this.$store.getters.accessToken
        await axios.post('/das/auth/reset_client_password/', payload)
            .then(response => {
              if (response.status === 200) {
                this.overlay = false;
                this.info_type = 'error'
                this.info_desc = 'Password changed , proceed to login'
                this.error_message = true
              }
            })
            .catch((error) => {
              if (error.response.status === 401) {
                this.overlay = false
                this.info_type = 'error'
                this.info_desc = error
                this.error_message = true
              } else if (error.response.status === 400) {
                this.overlay = false
                this.info_type = 'error'
                this.info_desc = error
                this.error_message = true
              } else if (error.response.status === 500) {
                this.overlay = false
                this.info_type = 'error'
                this.info_desc = error
                this.error_message = true
              }
              else{
                this.overlay = false
                this.info_type = 'error'
                this.info_desc = error
                this.error_message = true
              }
            })
      }

    },
    async clear () {
      this.email = null
      this.password = null
      this.password_1 = null
      this.password_2 = null
      this.id_number = null
      this.SignUpState = false
      this.error_message = false
    },

    complete (index) {
      this.list[index] = !this.list[index]
    },
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');
#login-page {
  box-sizing: border-box;
  background: #f6f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  height: 100vh;
  margin: 20px 0 30px;
}

h1 {
  font-weight: bold;
  margin: 0;
}

h2 {
  text-align: center;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

span {
  font-size: 12px;
}

a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.button_class {
  border-radius: 20px;
  border: 1px solid #00587A;
  background-color: #00587A;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

button:active {
  transform: scale(0.95);
}

button:focus {
  outline: none;
}

button.ghost {
  background-color: transparent;
  border-color: #FFFFFF;
}

form {
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.input_class {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.container1 {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25),
  0 10px 10px rgba(0,0,0,0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container1.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.container1.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%, 49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%, 100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container1.right-panel-active .overlay-container{
  transform: translateX(-100%);
}

.overlay {
  background: #00587A;
  background: -webkit-linear-gradient(to right, #00587A, #00587A);
  background: linear-gradient(to right, #00587A, rgb(0,88,122));
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #FFFFFF;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container1.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.container1.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container1.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #DDDDDD;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

footer {
  background-color: #222;
  color: #fff;
  font-size: 14px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
}

footer p {
  margin: 10px 0;
}

footer i {
  color: #FF0006;
}

footer a {
  color: #00587A;
  text-decoration: none;
}
</style>
