import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { AxiosInstance as axios } from 'axios'
// import router from "../router"; // our vue router instance
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage:
      'https://www.softwebsolutions.com/wp-content/uploads/2020/08/health-insurance-industry.jpg',
    drawer: null,
    base_url: 'http://127.0.0.1:8000',
    download_url: 'http://127.0.0.1:8000',
    refresh_token: "",
    user_ip: "",
    access_token: "",
    loggedInUser: "",
    isAuthenticated: false,
    maker_checker:false,
    user_type:"",
    approved_logo:"",
    approved_color:"",
    is_ssb:false,
    is_fbcmanager:false,
    is_auditor:false,
    is_bss:false,
    unpaidInvoiceAccess: true,
    invoice_paid: false,
    is_accounts:false,
    is_agent:false,
    institution:'',
    OrgId:'',
    branch:[],
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    setRefreshToken: function(state, refreshToken) {
      state.refresh_token = refreshToken;
    },
    setAccessToken: function(state, accessToken) {
      state.access_token = accessToken;
    },
    setUserIp: function(state, userIp) {
      state.user_ip = userIp;
    },
    // sets state with user information and toggles
    // isAuthenticated from false to true
    setAuthenticated: function(state){
      state.isAuthenticated = true;
    },
    setLoggedInUser: function(state, user) {
      state.loggedInUser = user;
    },
    setLogo: function(state, approved_logo){
      state.approved_logo = approved_logo;
    },
    setMakerChecker: function(state, maker_checker){
      state.maker_checker = maker_checker;
    },
    setUserType: function(state, user_type){
      state.user_type = user_type;
    },
    setColor: function(state, approved_color) {
      state.approved_color = approved_color;
    },
    setIsSSB: function(state, is_ssb) {
      state.is_ssb = is_ssb;
    },
    setIsFBCManager: function(state, is_fbcmanager) {
      state.is_fbcmanager = is_fbcmanager;
    },
    setIsBss: function(state, is_bss) {
      state.is_bss = is_bss;
    },
    setUnpaidInvoiceAccess: function(state, unpaidInvoiceAccess) {
      state.unpaidInvoiceAccess = unpaidInvoiceAccess;
    },
    setIsAccounts: function(state, is_accounts) {
      state.is_accounts = is_accounts;
    },
    setIsAgent: function(state, is_agent) {
      state.is_agent = is_agent;
    },
    setInvoicePaid: function(state, invoice_paid) {
      state.invoice_paid = invoice_paid;
    },
    setIsAuditor: function(state, is_auditor) {
      state.is_auditor = is_auditor;
    },
    setinstitution: function(state, institution){
      state.institution = institution;
    },
    setOrgId: function(state, OrgId){
      state.OrgId = OrgId;
    },
    setbranch: function(state, branch){
      state.branch = branch;
    },
    // delete all auth and user information from the state
    clearUserData: function(state) {
      state.refresh_token = "";
      state.maker_checker = false;
      state.user_type = "";
      state.approved_color = "";
      state.approved_logo = "";
      state.access_token = "";
      state.loggedInUser = "";
      state.invoice_paid = false;
      state.isAuthenticated = false;
      state.unpaidInvoiceAccess = true;
      state.is_ssb = false;
      state.is_fbcmanager = false;
      state.is_auditor = false;
      state.is_bss = false;
      state.is_accounts = false;
      state.is_agent = false;
      state.institution = "";
      state.OrgId = "";
      state.branch = "";
    },
  },
  actions: {
    refreshToken: async ({ state, commit }) => {
      const refreshUrl = "refresh/";
      try {
        await axios
            .post(refreshUrl, { refresh: state.refresh_token })
            .then(response => {
              if (response.status === 200) {
                commit("setAccessToken", response.data.access);
              }
            });
      } catch (e) {
        console.log(e.response);
      }
    },
    fetchUser: async ({ commit }) => {
      const currentUserUrl = "";
      try {
        await axios.get(currentUserUrl).then(response => {
          if (response.status === 200) {
            commit("setLoggedInUser", response.data);
          }
        });
      } catch (e) {
        console.log(e.response);
      }
    }
  },
  getters: {
    invoice_paid: state => state.invoice_paid,
    loggedInUser: state => state.loggedInUser,
    user_ip: state => state.user_ip,
    maker_checker: state => state.maker_checker,
    user_type: state => state.user_type,
    isAuthenticated: state => state.isAuthenticated,
    accessToken: state => state.access_token,
    refreshToken: state => state.refresh_token,
    approved_logo: state => state.approved_logo,
    approved_color: state => state.approved_color,
    is_ssb: state => state.is_ssb,
    is_fbcmanager: state => state.is_fbcmanager,
    is_auditor: state => state.is_auditor,
    unpaidInvoiceAccess: state => state.unpaidInvoiceAccess,
    is_agent: state => state.is_agent,
    is_bss: state => state.is_bss,
    is_accounts: state => state.is_accounts,
    institution: state => state.institution,
    OrgId: state => state.OrgId,
    branch: state => state.branch
  },
})
